var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("b-spinner", {
            staticStyle: { position: "absolute", "border-right-color": "black" }
          })
        : _vm._e(),
      _c(
        "b-overlay",
        { attrs: { show: _vm.showOverlay, rounded: "sm", "no-center": "" } },
        [
          _c("iframe", {
            ref: "iframe",
            staticClass: "sentry-ignore",
            class: { invisible: _vm.loading },
            staticStyle: {
              "min-height": "60px",
              "background-color": "white",
              padding: "10px"
            },
            attrs: {
              width: "100%",
              height: "0",
              frameborder: "0",
              scrolling: "no",
              src: _vm.iframeUrl
            },
            on: { load: _vm.onIframeLoad }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }