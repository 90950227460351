<template lang="pug">

div
  b-spinner(v-if='loading', style='position: absolute; border-right-color: black;')
  b-overlay(:show="showOverlay" rounded="sm" no-center)
    iframe.sentry-ignore(
      ref='iframe'
      width='100%'
      height='0'
      frameborder='0'
      scrolling='no'
      :src='iframeUrl'
      style='min-height: 60px; background-color: white; padding: 10px'
      :class='{ invisible: loading }'
      @load='onIframeLoad'
    )

</template>

<script>

  import { ucfirst } from '@/functions'

  export default {
    name: 'CharlyProjectsDetailAttributes',
    props: {
      url: String,
    },
    data () {
      return {
        loading: true,
        showOverlay: false,
      }
    },
    watch: {
      $route() {
        this.loading = true
      }
    },
    methods: {
      onIframeLoad () {
        this.loading = false
        this.sendMessage('init')
      },
      sendMessage (event, params) {
        //console.log('Sending to iframe', event, params)
        this.$refs.iframe.contentWindow.postMessage(
          JSON.stringify({
            event,
            params
          }),
          this.iframeOrigin
        )
      },
      onMessage (msg) {
        //console.log('Received from iframe', msg.origin, this.iframeOrigin)
        if (this.$refs.iframe.contentWindow !== msg.source) return
        if (msg.origin != this.iframeOrigin) return

        const data = JSON.parse(msg.data)

        if (data.event) {
          const methodName = 'onIframe' + ucfirst(data.event)
          if (typeof this[methodName] == 'function') {
            this[methodName](data.params)
          } else {
            console.log(`Unknown message event: ${data.event} (${methodName}) - ${JSON.stringify(data.params)}`)
          }
        }
      },
      onIframeBeforeunload() {
        this.showOverlay = true
      },
      onIframeInitDone () {
        this.sendMessage('checkUser', { userId: this.$root.$data.me.UserAccountID })
        this.sendMessage('removeTargets')
        this.sendMessage('hideClass', { className: 'hide-project-portal' })
        this.showOverlay = false
        this.$emit('load')
      },
      onIframeBodyResize ({ rect }) {
        this.$refs.iframe.style.height = rect.height + rect.top + 'px'
      },
      onIframeNeedLogin () {
        this.sendMessage('login', { 
          jwt: localStorage.getItem('jwt'),
          url: this.iframeUrl
        })
      },
      onIframeScrollY ({ y }) {
        window.scrollTo({ top: y})

        this.sendMessage('scrollTo', { 
          top: 0
        }) 
      },
      onIframeError ({ message }) {
        this.$notify({
              type: 'error',
              text: `An error occurred: ${message}`,
              duration: -1,
            });
      },
    },
    computed: {
      iframeUrl () {
        return `${process.env.VUE_APP_CE_LOCAL_URL}${this.url}`
      },
      iframeOrigin () {
        if (this.iframeUrl.startsWith('/')) return window.origin
        return new URL(this.iframeUrl).origin
      }
    },
    created () {
      window.addEventListener('message', this.onMessage)
    },
    destroyed () {
      window.removeEventListener('message', this.onMessage)
    }
  }
</script>